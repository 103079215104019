import OpenAI from "openai";

const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true
});


export async function getGPTResponse(durationVal) {
  try {
    const chatCompletion = await openai.chat.completions.create({
      messages: [
        { role: "system", content: "You are a helpful assistant that follows all the requirements and directives of the user." },
        {
          role: "user",
          content: "Respond with a music playlist whose duration of all the songs in it combined equals " + Math.floor(parseFloat(durationVal)) + " minutes. Only respond with the 'artist name - song title' format.",
        },
      ],
      model: "gpt-3.5-turbo-0125",
    });

    return chatCompletion.choices[0].message.content;
  } catch (error) {
    console.error('Error fetching GPT response:', error);
    throw error;
  }
}
