// mapsAPI.js
export function getDistance(origin, destination, callback) {
  if (!window.google) {
    console.error('Google Maps JavaScript API not loaded');
    return;
  }

  const service = new window.google.maps.DistanceMatrixService();
  const request = {
    origins: [origin],
    destinations: [destination],
    travelMode: window.google.maps.TravelMode.DRIVING,
    unitSystem: window.google.maps.UnitSystem.IMPERIAL,
    avoidHighways: false,
    avoidTolls: false,
  };

  service.getDistanceMatrix(request, (response, status) => {
    if (status === 'OK') {
      const durationVal = response.rows[0].elements[0].duration.value;
      callback(durationVal/60, 'OK');
    } else {
      console.error('Error fetching duration:', status);
      callback(null, status);
    }
  });
}
