import './App.css';
import React, { useState, useEffect } from 'react';
import { getDistance } from './mapsAPI';
import { getGPTResponse } from './gptAPI';

function App() {
  const [input1, setInput1] = useState('');
  const [input2, setInput2] = useState('');
  const [savedInput1, setSavedInput1] = useState('');
  const [savedInput2, setSavedInput2] = useState('');
  const [duration, setDuration] = useState('');
  const [error, setError] = useState('');
  const [gptResponse, setGptResponse] = useState('');

  useEffect(() => {
    const loadGoogleMapsScript = async () => {
      const script = document.createElement('script');
      const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
      const response = await fetch(`https://maps.googleapis.com/maps/api/js?key=${apiKey}`);
      script.src = response.url; 
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
    };
    loadGoogleMapsScript();
  }, []);

  const handleInput1Change = (event) => {
    setInput1(event.target.value);
  };

  const handleInput2Change = (event) => {
    setInput2(event.target.value);
  };

  const handleSave = () => {
    setSavedInput1(input1);
    setSavedInput2(input2);
    console.log('Inputs saved:', input1, input2); // Debugging statement
    getDistance(input1, input2, (durationVal, status) => {
      if (status === 'OK') {
        setDuration(Math.floor(parseFloat(durationVal)));
        setError(''); // Clear any previous error
        console.log('Duration set:', durationVal); // Debugging statement
        if (durationVal > 200) {
          setDuration(200);
          console.log('Duration capped at 200 minutes:', durationVal); // Debugging statement
        }
        
        getGPTResponse(durationVal).then(response => {
          setGptResponse(response.split('\n').map((item, index) => <li key={index}>{item}</li>));
        }).catch(error => {
          console.error('Error fetching GPT response:', error);
        });
      } else {
        console.log('Error fetching duration:', status); // Debugging statement
        setError('Could not retrieve directions, please check your input.');
        console.error('Error fetching duration:', status);
      }
    });
  };
 
  return (
    <div className="App">
      <div className="column">
        <h1>Input Form</h1>
        <div>
          <label>
            Origin: 
             <input type="text" value={input1} onChange={handleInput1Change} />
          </label>
        </div>
        <div>
          <label>
            Destination: 
             <input type="text" value={input2} onChange={handleInput2Change} />
          </label>
        </div>
        <button onClick={handleSave}>Save</button>
        <p>Saved Origin: {savedInput1}</p>
        <p>Saved Destination: {savedInput2}</p>
        <p>Duration: {duration} minutes</p>
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </div>
      <div className="column">
        <div className="main-content">
          {/* Main content goes here */}
        </div>
        <div className="sidebar">
          <h2>GPT Response</h2>
          <ul>{gptResponse}</ul>
        </div>
      </div>
    </div>
  );
}

export default App;
